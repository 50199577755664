<template>
	<v-col>
		<v-layout justify-end
			><v-btn color="primary" @click="backToSearch()" dark
				>返回 Back</v-btn
			></v-layout
		>
		<v-col>
			<v-layout justify-center class="page-title"
				><strong>{{ labelsContent.submissionTitles[action] }}</strong></v-layout
			>
			<v-layout justify-center>
				<v-layout justify-center>
					<v-form ref="form" class="sub-form" :disabled="action === 'view'">
						<v-card>
							<v-card-text
								><strong>地點編號 Location ID:</strong><br />
								{{ submission.location.id }}</v-card-text
							>
							<v-card-text
								><strong>地址 Address:</strong><br />
								<span
									v-html="
										concatAddr(
											submission.location.address_tc,
											submission.location.address_en,
										)
									"
								></span>
							</v-card-text>
							<v-card-text
								><strong>{{locationTypeLabel}}:</strong>
								<br />{{ submission.location.location_no }}</v-card-text
							>
						</v-card>
						<v-divider black style="margin: 20px 0px 40px 0px;"></v-divider>
						<v-card>
							<v-card-text
								><strong>檢查日期 Examination date:</strong><br />
								{{
									formatDate(submission.examination_date, 'DD/MM/YYYY')
								}}</v-card-text
							>
							<v-card-text
								><strong>機器類型 Machine Type:</strong><br />
								{{ selectedMachine.text }}</v-card-text
							>
						</v-card>
						<v-col>
							<v-card
								v-for="(item, index) in selectedMachine.components"
								:key="item.label"
								elevation="0"
								class="form-card"
							>
								<v-card-text class="form-fld-lbl">{{ item.label }}</v-card-text>
								<v-img :src="image_urls[index]" :contain="true" max-height="300px" height="300px"></v-img>
								<v-layout>
									<v-col>
										<v-file-input
											@change="previewImage(images[index], index)"
											v-model="images[index]"
											placeholder="瀏覽檔案 Browse File"
											:prepend-icon="images[index] ? null : 'mdi-camera'"
											accept="image/png, image/jpeg, image/jpg, image/heic"
											outlined
											dense
											:clearable="false"
											:rules="updateOptionalPhoto(labelsContent.UPLOAD_FILE_SIZE)"
										></v-file-input>
									</v-col>
									<v-col v-if="images[index]">
										<v-btn
											color="red darken-4"
											@click="removeImage(index)"
											block
											dark
											>刪除 Remove</v-btn
										>
									</v-col>
								</v-layout>
							</v-card>
						</v-col>
						<v-col>
							<v-card elevation="0" class="form-card">
								<v-card-text class="form-fld-lbl">備註 Remarks</v-card-text>
								<v-textarea v-model="remarks" outlined></v-textarea>
							</v-card>
						</v-col>
						<v-checkbox
							:label="tcContent"
							v-model="agreeTNC"
							:rules="checkTC"
						></v-checkbox>
						<v-btn
							@click="submitForm"
							color="blue"
							v-if="action !== 'view'"
							block
							dark
							>{{ labelsContent.buttonLabels[action] }}</v-btn
						>
					</v-form>
				</v-layout>
			</v-layout>
		</v-col>
	</v-col>
</template>

<script>
// import EnginerService from '../service/engineer-service';
import * as submissionLabel from '../config/submission-details.config';
import { updateOptionalPhoto } from '../config/form-rules.config';
// const engineerService = new EnginerService();

// import EnginerService from '../service/engineer-service';
// const engineerService = new EnginerService();
const heic2any = require('heic2any');
import SubmissionService from '../service/submission-service';
const submissionService = new SubmissionService();
import LoginService from '../service/login-service';
const loginService = new LoginService();
const moment = require('moment-timezone');

export default {
	name: 'SubmissionDetails',
	props: {
		action: {
			type: String,
		},
		id: {
			type: String,
		},
	},
	computed: {
		computedDateFormatted() {
			return this.formatDate(this.examDate);
		},
	},
	data: () => ({
		form: null,		
		updateOptionalPhoto: updateOptionalPhoto,
		examDate: '',
		examDateRule: [
			(v) => !!v || '檢查日期必須填寫 Examination date is Required',
		],
		selectedMachine: {},
		labelsContent: submissionLabel,
		machineTypes: [],
		// location_data: {},
		remarks: '',
		agreeTNC: false,
		images: {},
		// allImages: [], //for swtiching between machine type if necessary
		image_urls: [],
		checkTC: [
			(val) => {
				return val;
			},
		],
		tcContent: '',
		submission: {},
		locationTypeLabel: ''
	}),
	methods: {
		isValidFileType(type){
			const validTypes = ['image/heic','image/jpg','image/jpeg','image/png'];
			return validTypes.includes(type);
		},
		checkImg(img){
			if (!img){
				return false;
			}else{
				return this.isValidFileType(img.type);
			}
		},
		setTerms() {
			let tcDateStr = 'YYYY年MM月DD日';
			let enDateStr = 'DD/MM/YYYY';
			if (this.submission.examination_date != '') {
				let momentDate = moment(this.submission.examination_date, 'YYYY-MM-DD'); //date picker format must be YYYY-MM-DD
				tcDateStr = momentDate.format('YYYY年MM月DD日');
				enDateStr = momentDate.format('DD/MM/YYYY');
			}
			this.tcContent = `本人確認所上載相片為地點編號 ${this.submission.location.id} 於${tcDateStr}定期檢驗時所拍攝。I confirmed that the photos uploaded is taken during periodic examination on ${enDateStr} at location ID ${this.submission.location.id}`;
		},
		getCurrentDate() {
			return moment().format();
		},
		formatDate(dateStr, formatStr) {
			return moment
				.utc(dateStr)
				.tz('Asia/Hong_Kong')
				.format(formatStr);
		},
		backToSearch() {
			this.$router.push({ path: '/search' });
		},
		concatAddr(addr_tc, addr_en) {
			return addr_tc + '<br/>' + addr_en;
		},
		renameImage(image, key_index) {
			return new Promise((resolve, reject) => {
				if (image.type.toLowerCase() == 'image/heic') {
					var blob = image;
					heic2any({
						blob: blob,
						toType: 'image/jpeg',
						quality: 0.7,
					})
						.then((resultBlob) => {
							// const url = resultBlob ? URL.createObjectURL(resultBlob) : '';

							console.log(this.selectedMachine.components[key_index].prefix);
							let newFileName =
								this.selectedMachine.components[key_index].prefix + '.jpg';
							let file = null;
							try {
								file = new File([resultBlob], newFileName, {
									type: 'image/jpeg',
								});
							} catch (fileErr) {
								console.log('fileErr');
								console.log(fileErr);
								var newBlob = new Blob([resultBlob], { type: 'image/jpeg' });
								newBlob['name'] = newFileName;
								// newBlob['lastModifiedDate'] = new Date();
								file = newBlob;
							}
							console.log(file);
							resolve(file);
							// console.log(url);
							// console.log(this.images);
							// this.$nextTick(() => {
							// 	// DOM is now updated
							// 	// `this` is bound to the current instance

							// });
							//this.image_urls[key_index] = url;

							// do trick to update array - https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
							// this.image_urls.splice(key_index, 1, url);
							// console.log(this.image_urls);
						})
						.catch((x) => {
							console.log('Go to catch block');
							console.log(x);
							reject(x);
						});
				} else {
					// const url = image ? URL.createObjectURL(image) : '';
					// this.image_urls[key_index] = url;
					// console.log(this.image_urls);
					let imgPath = image.name.split('.');
					let ext = imgPath[imgPath.length - 1];

					let newFileName =
						this.selectedMachine.components[key_index].prefix + '.' + ext;
					let file = null;
					try {
						file = new File([image], newFileName, { type: image.type });
					} catch (fileErr) {
						// console.log('fileErr');
						// console.log(fileErr);
						var newBlob = new Blob([image], { type: image.type });
						newBlob['name'] = newFileName;
						// newBlob['lastModifiedDate'] = new Date();
						file = newBlob;
					}
					console.log(file);
					resolve(file);
				}
			});
		},
		previewImage(image, key_index) {
			// console.log(image.size);
			//to prevent error when removing the image
			if (image) {
				if (this.isValidFileType(image.type)){
					if (image.size < this.labelsContent.UPLOAD_FILE_SIZE) {
						if (image.type.toLowerCase() == 'image/heic') {
							let loader = this.$loading.show({
								// Optional parameters
								container: null,
								canCancel: false,
							});
							var blob = image;
							heic2any({
								blob: blob,
								toType: 'image/jpeg',
								quality: 0.7,
							})
								.then((resultBlob) => {
									const url = resultBlob ? URL.createObjectURL(resultBlob) : '';

									loader.hide();
									// console.log(url);
									// console.log(this.images);
									// this.$nextTick(() => {
									// 	// DOM is now updated
									// 	// `this` is bound to the current instance

									// });
									//this.image_urls[key_index] = url;

									// do trick to update array - https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
									this.image_urls.splice(key_index, 1, url);
									// console.log(this.image_urls);
								})
								.catch((x) => {
									console.log(x);
								});
						} else {
							const url = image ? URL.createObjectURL(image) : '';
							this.image_urls.splice(key_index, 1, url);
							console.log(this.image_urls);
						}
					}
					//do nothing if the file size > 3MB
				}else{
					//not supported file type
					console.log('not support file type!');
				}
			}
		},
		// previewImage(image, key_index) {
		// 	console.log(image);
		// 	const url = image ? URL.createObjectURL(image) : '';
		// 	this.image_urls[key_index] = url;

		// 	/*
		//   	Initialize a File Reader object
		// 	 */
		// 	let reader = new FileReader();

		// 	/*
		// 	Add an event listener to the reader that when the file
		// 	has been loaded, we flag the show preview as true and set the
		// 	image to be what was read from the reader.
		// 	*/
		// 	reader.addEventListener(
		// 		'load',
		// 		function() {
		// 			// this.showPreview = true;
		// 			// this.imagePreview = reader.result;
		// 			console.log(reader.result);
		// 			var blobObj = new Blob([reader.result], { type: image.type });
		// 			heic2any({
		// 				blob: blobObj,
		// 				toType: 'image/jpeg',
		// 				quality: 0.5, // cuts the quality and size by half
		// 			}).then((res) => {
		// 				console.log(res);
		// 			});

		// 			// console.log(res);
		// 		}.bind(this),
		// 		false,
		// 	);

		// 	reader.readAsDataURL(image);
		// },
		removeImage(key_index) {
			this.images[key_index] = null;
			// this.image_urls[key_index] = null;
			this.image_urls[key_index] = this.submission.photos[key_index].url;
		},
		promptSnackBar(msg, color, extraProps = {}) {
			this.$emit('snack-bar', {
				color,
				msg,
				extraProps,
			});
		},
		async submitForm() {
			// console.log(this.image_urls);
			// console.log(Array.isArray(this.images));

			const valid = this.$refs.form.validate();
			if (valid) {
				console.log(valid);
				let loader = this.$loading.show({
					// Optional parameters
					container: null,
					canCancel: false,
				});
				//get presigned url
				try {
					// console.log(this.image_urls.length);
					let imgArr = [];
					for (let i = 0; i < this.image_urls.length; i++) {
						if (this.images[i]) {
							// imgArr[i] = this.images[i];
							imgArr[i] = await this.renameImage(this.images[i], i);
						} else {
							imgArr[i] = null;
						}
					}
					let presignedUrls = await submissionService.getPresignedUrls(imgArr);
					console.log(presignedUrls);
					let photoArr = [];
					//upload to S3
					for (let i = 0; i < imgArr.length; i++) {
						let retry = 0;
						let isUploaded = false;
						if (imgArr[i] != null) {
							while (retry < 2 && !isUploaded) {
								try {
									let result = await submissionService.uploadToS3(
										imgArr[i],
										presignedUrls[i].presigned_url,
									);
									isUploaded = result.statusText == 'OK';
									console.log(isUploaded);
								} catch (uploadErr) {
									console.log(uploadErr);
								}
								retry++;
							}
							//check the upload is success or not
							if (!isUploaded) {
								throw '上載失敗，請重試！Upload failed, please try again!';
							}
							photoArr.push({ photo_no: i, url: presignedUrls[i].bucket_path });
						}
					}
					//submit the submission
					let reqData = {};
					// reqData.location_id = this.location_data.location_id;
					// reqData.examination_date = this.examDate;
					// reqData.machine_type = this.selectedMachine.value;
					reqData.remark = this.remarks;
					reqData.photos = photoArr;
					console.log(reqData);
					try {
						let subRes = await submissionService.updateSubmission(
							loginService.getEngineerNo(),
							this.id,
							reqData,
						);
						console.log(subRes);
						if (subRes.data.success) {
							this.promptSnackBar('遞交成功! Submit success!', 'success');
						} else {
							throw '遞交失敗，請重試！Submit failed, please try again!';
						}
					} catch (subErr) {
						console.log(subErr);
						throw '遞交失敗，請重試！Submit failed, please try again!';
					}
					loader.hide();
					this.$router.push('/search');
				} catch (err) {
					//error for getting presigned urls
					console.log(err);
					this.promptSnackBar(err, 'error');
					loader.hide();
				}
			}
		},
		new() {
			// call new api
			console.log('create');
		},
		edit() {
			// call edit api
			console.log('edit');
		},
		machineTypeChange(item) {
			console.log(item.value);

			//clear the image_urls and images
			this.image_urls = new Array(
				this.labelsContent.liftType[0].components.length,
			);
			this.images = {};
		},
	},
	created() {
		//init data
		submissionService
			.getSubmission(loginService.getEngineerNo(), this.id)
			.then((res) => {
				if (res.data.success) {
					this.submission = res.data.result;
					console.log(this.submission);
					if (
						this.submission.location.location_type ==
						submissionLabel.LOCATION_LIFT_TYPE
					) {
						this.machineTypes = this.labelsContent.liftType;

						let machine = this.labelsContent.liftType.find((obj) => {
							return obj.value === this.submission.machine_type;
						});

						this.selectedMachine = machine;
						this.locationTypeLabel = '升降機編號 Lift No.';


						// this.selectedMachine = this.labelsContent.liftType[
						// 	this.submission.machine_type
						// ];
					} else {
						this.machineTypes = this.labelsContent.escalatorType;
						// this.selectedMachine = this.labelsContent.escalatorType[
						// 	this.submission.machine_type
						// ];

						let machine = this.labelsContent.escalatorType.find((obj) => {
							return obj.value === this.submission.machine_type;
						});

						this.selectedMachine = machine;
						this.locationTypeLabel = '扶手電梯編號 Escalator No.'
					}
					this.image_urls = new Array(this.submission.photos.length);
					for (let i = 0; i < this.submission.photos.length; i++) {
						console.log(this.submission.photos[i].url);
						// this.image_urls.splice(i, 1, this.submission.photos[i].url);
						this.image_urls[i] = this.submission.photos[i].url;
					}
					this.remarks = this.submission.remark;

					this.setTerms();
				} else {
					this.promptSnackBar(
						'記錄不存在，請重新搜索。Submission not found, please search again.',
						'error',
					);
					//submission not found
					this.$route.push('/search');
				}
			})
			.catch((err) => {
				console.log(err);
				this.promptSnackBar(
					'系統錯誤，請稍後再試！System Error, please try again!',
					'error',
				);
				//submission not found
				this.$route.push('/search');
			});
	},
};
</script>

<style lang="scss">
.sub-ttl {
	font-size: x-large;
	padding-bottom: 1rem;
}

.translate-down {
	transform: translateY(2rem);
}

.form-fld-lbl {
	margin-bottom: -1rem;
	transform: translateX(-1.8rem);
}

.form-card {
	margin-bottom: -1rem;
}
</style>
